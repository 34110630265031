<template>
  <div @scroll="isBottom" id="list-purchase" :class="!isMobileView ? 'biz-container' :''">
    <b-modal
      id="modal-response"
      hide-footer
      header-class="border-0"
      v-model="modalVisible"
      centered
    >
      <template>
        <div class="w-full d-flex flex-column text-center px-5 pb-5">
          <span class="h4 mb-4" style="color: #015ca1"
            >Pastikan anda telah menerima pesanan anda</span
          >
          <button
            class="btn text-light corner mb-2 action-button"
            @click="onChangeStateOrder('COMPLETED')"
            style="background: #015289"
          >
            Sudah Diterima
          </button>
          <button
            class="btn action-button"
            @click="modalVisible = false"
            style="border: solid 1px #015289; color: #015289"
          >
            Belum Diterima
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-checkout-waiting" :no-close-on-backdrop="!feedbackCart?.length > 0" hide-footer :hide-header="!feedbackCart?.length > 0" centered v-model="showModalReorder" :size="feedbackCart?.length > 0 ? 'md' : 'sm'">
      <div class="d-flex flex-column align-items-center text-center py-3">
        <template v-if="feedbackCart?.length > 0">
          <template v-if="feedbackCart?.filter((item) => item.status).length === feedbackCart?.length">
            <a-icon class="mb-2" type="check-circle" style="font-size: 50px; color: green"/>
            <div>Berhasil menambahkan semua pesanan anda ke keranjang.</div>
            <div class="mt-2" style="color:#929292; font-size: 12px;">Anda akan dialihkan ke halaman keranjang, dalam {{ countdownRedirect }} detik</div>
            <a-button class="mt-4" @click="goToCart">
              Alihkan Sekarang
              <a-icon type="arrow-right"></a-icon>
            </a-button>
          </template>
          <template v-else-if="feedbackCart?.filter((item) => !item.status).length === feedbackCart?.length">
            <a-icon class="mb-2" type="info-circle" style="font-size: 50px; color: red"/>
            <div>Gagal menambahkan semua pesanan anda ke keranjang.</div>
            <div class="d-flex justify-content-center mt-2" style="gap: 10px;">
              <a-button class="mt-4" @click="goToCatalogue">
                <a-icon type="gold"></a-icon>
                Cari produk lainnya
              </a-button>
            </div>
          </template>
          <template v-else>
            <a-icon class="mb-2" type="info-circle" style="font-size: 50px; color: orange"/>
            <div><b>{{ feedbackCart?.filter((item) => !item.status).length }}</b> produk gagal ditambahkan ke keranjang.</div>
            <a-divider class="my-2"/>
            <ol class="w-100" style="text-align: left;">
              <li v-for="(item, index) in feedbackCart?.filter((item) => !item.status)" :key="index">
                {{ item.title }} <b>({{ item.sku }})</b>
              </li>
            </ol>
            <div class="d-flex justify-content-center mt-2" style="gap: 10px;">
              <a-button class="mt-4" @click="goToCatalogue">
                <a-icon type="gold"></a-icon>
                Cari produk lainnya
              </a-button>
              <a-button class="mt-4" @click="goToCart">
                <a-icon type="shopping-cart"></a-icon>
                Lanjutkan ke keranjang
              </a-button>
            </div>
          </template>
        </template>
        <template v-else>
          <DefaultLoader class="rotate-img" :width="50" :height="50"/>
          <span class="font-weight-bold mt-3 name">Mohon Menunggu</span>
          <span class="">Sedang menambahkan pesanan anda ke keranjang</span>
        </template>
      </div>
    </b-modal>
    <ModalPreventAddCart :showModal="showNotificationPreventAddCart"
      @emitCloseModal="(e) => showNotificationPreventAddCart = e" />
    <h2 class="mb-5">{{ $t("menu.purchase.transaction") }}</h2>
    <div class="waiting-payment-mobile" @click="gotToWaitingPayment">
      <div class="d-flex align-items-center">
        <RedWalletIcon color="#000000" class="mr-2" />
        <span> {{ $t("menu.purchase.waiting_payment.title") }} </span>
      </div>
      <div style="display: flex; align-items: center;">
        <!-- <span
          style="
            background: #ff0000;
            border-radius: .25rem;
            padding: 3px 5px;
            color: white;
            margin-right: 10px;
            flex-shrink: 0;
          "
        >
          {{ total_waiting_payment }}</span
        > -->
        <ArrowCaveron />
      </div>
    </div>
    <div class="mb-3">
      <div
        class="d-flex container-button-type gap-12 justify-content-start align-items-center mb-3"
        style="
          border: 1px solid #f3f3f3;
          border-radius: 16px;
          padding: 10px;
          overflow-x: auto;
        "
      >
        <div
          v-for="{title, key} in listTab"
          :key="key"
          class="w-full border-2"
          style="min-width: max-content"
        >
          <button
            class="btn flex ml-1 w-auto btn-order-state"
            :class="{ active: key === activeKey, inactive: key !== activeKey}"
            @click="changeType(key)"
          >
            {{ $t(`menu.purchase.tabs.${title}`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="waiting-payment-desktop" @click="gotToWaitingPayment">
      <div class="d-flex align-items-center">
        <RedWalletIcon color="#000000" class="mr-2" />
        <span> {{ $t("menu.purchase.waiting_payment.title") }} </span>
      </div>
      <div style="display: flex; align-items: center">
        <span
          style="
            background: #ff0000;
            border-radius: 50%;
            padding: 3px 5px;
            color: white;
            margin-right: 10px;
          "
          >{{ total_waiting_payment }}</span
        >
        <ArrowCaveron />
      </div>
    </div>
    <div
      class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5"
      v-if="!loading && data.length === 0"
    >
      <div
        v-if="activeKey === 0"
        class="mt-3 ml-2 h4 align-items-center d-flex flex-column"
      >
        <div class="d-flex align-items-center mb-4">
          <EmptyIcon />
        </div>
        <span style="color: #000000" class="ml-2 desc-empty-state"
          >Belum ada transaksi yang dilakukan. Mulai melakukan transaksi
          sekarang untuk melihat riwayat transaksi Anda.</span
        >
        <div class="card-footer border-top-0 mt-1">
          <button @click="goToHome" class="btn w-100 button-empty-state">
            {{ $t("menu.purchase.shopNow") }}
          </button>
        </div>
      </div>
      <div v-else class="w-35 d-flex align-items-center flex-column pb-5">
        <div class="d-flex align-items-center">
          <EmptyFilterIcon />
        </div>
        <span style="color: #000000" class="ml-2 h4 mt-3 desc-empty-filter"
          >Belum ada transaksi yang diterima. Silakan lakukan transaksi untuk
          melihat status konfirmasi di sini.</span
        >
      </div>
    </div>
    <template v-else>
      <div
        id="wrap"
        class="pr-0"
        v-for="(
          {
            order_number,
            po_number,
            firstItem,
            theRestItem,
            status,
            created_at,
            newTotal,
            id,
            title,
          },
          index
        ) in data"
        :key="index"
        @click="showDetail(id, 'mobile')"
      >
        <div class="card d-flex justify-content-center">
          <div class="card-header d-flex justify-content-between">
            <div class="number-product">
              <div class="h5 mr-2" style="color: black">
                {{ order_number || po_number }}
              </div>
              <div class="h6" style="color: #929292">{{ created_at }}</div>
            </div>
            <div
              class="btn status text-white tag-status"
              :style="`font-weight: bold; background: var(${bgColor[status]}); color: var(${textColor[status]}) !important; height: min-content;`"
            >
              {{ $t(`menu.purchase.tabs.${title}`) }}
            </div>
          </div>
          <div
            class="card-body d-flex justify-content-between align-items-center"
            style="flex-wrap: wrap"
          >
            <div class="d-flex" style="width: 500px">
              <div v-if="firstItem && firstItem.image_url" class="mr-3">
                <img
                  :src="firstItem && firstItem.image_url"
                  loading="lazy"
                  style="max-width: 100%; width: 78px"
                />
              </div>
              <div class="customBox">
                <span style="font-weight: bold">{{
                  firstItem && firstItem.title
                }}</span>
                <span style="color: #929292">{{
                  `${firstItem && firstItem.qty} ${firstItem && firstItem.uom}`
                }}</span>
                <span v-if="theRestItem">{{
                  `+${theRestItem} Produk lainya`
                }}</span>
              </div>
            </div>
            <div class="box-price">
              <p style="margin: 0">Total Pembayaran</p>
              <span class="h5" style="color: var(--biz-brand-legacy)">Rp. {{ newTotal }}</span>
            </div>
            <div
              class="d-flex hidden-button"
              style="flex-direction: column; gap: 10px"
            >
              <span
                style="
                  color: black;
                  border: 1px solid #000000;
                  background-color: #ffffff;
                  width: 200px;
                  display: none;
                "
                class="btn action-button"
                @click.stop="showHowToBayDetail(id)"
                v-if="status === 'SUBMITTED'"
                >
                  Lihat Cara Bayar
                </span
              >
              <span
                class="btn action-button btn-detil-transaksi w-100"
                @click.stop="showDetail(id)"
                >Detail Transaksi</span
              >
              <span
                v-if="status !== 'SHIPPING'"
                class="btn action-button"
                @click.stop="onReorder(true, id)"
                >Pesan Lagi</span
              >
              <span
                v-if="status === 'SHIPPING'"
                class="btn action-button"
                @click.stop="openModal(id)"
                >Pesanan Diterima</span
              >
              <button
                v-if="status === 'DELIVERED'"
                disabled
                style="color: #ababab; border: 1px solid #ababab"
                class="btn disabled action-button"
                @click.stop="() => undefined"
              >
                <span>
                  <i class="fe fe-clock"></i>
                </span>
                <span> Menunggu verifikasi </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
/* eslint-disable */

import {mapState} from "vuex";
import moment from "moment";
import RedWalletIcon from "@/components/Icons/RedWallet.vue";
import EmptyIcon from "@/components/Icons/Empty.vue";
import EmptyFilterIcon from "@/components/Icons/EmptyFilter.vue";
import ArrowCaveron from "@/components/Icons/ArrowCevron.vue";
import DefaultLoader from '@/components/Icons/DefaultLoader.vue'
import ModalPreventAddCart from '@/components/Purchase/ModalPreventAddCart.vue'

export default {
  props: ['isLoyalty'],
  name: "component-purchase-index",
  components: {
    RedWalletIcon,
    EmptyIcon,
    EmptyFilterIcon,
    ArrowCaveron,
    DefaultLoader,
    ModalPreventAddCart,
  },
  data: function () {
    return {
      bgColor: {
        SHIPPING: "--product-status-bg-shipping",
        COMPLETED: "--product-status-bg-completed",
        CANCELED: "--product-status-bg-canceled",
        CANCELREQUESTED: "--product-status-bg-gray",
        REJECTED: "--product-status-bg-rejected",
        VERIFIED: "--product-status-bg-gray",
        CREATED: "--product-status-bg-gray",
        ACCEPTED: "--product-status-bg-accepted",
        SUBMITTED: "--product-status-bg-submitted",
        DELIVERED: "--product-status-bg-delivered",
        CANCELEDPAYMENTEXPIRED: "--product-status-bg-canceled",
      },
      textColor: {
        ACCEPTED: "--product-status-text-accepted",
        SUBMITTED: "--product-status-text-submitted",
        DELIVERED: "--product-status-text-delivered",
        COMPLETED: "--product-status-text-completed",
        CANCELED: "--product-status-text-cancled",
        CANCELEDPAYMENTEXPIRED: "--product-status-text-cancled",
      },
      listTab: [
        {
          title: "all",
          value: ["ALL"],
          state: ["ALL"],
          shipping_states: [],
          key: 0,
        },
        {
          title: "waiting_confirmation",
          value: ["WAITING_CONFIRMATION"],
          state: ["VERIFIED", "CREATED", "SUBMITTED", "CANCELREQUESTED"],
          shipping_states: ["CREATED"],
          key: 1,
        },
        {
          title: "on_process",
          value: ["ON_PROCESS"],
          state: ["ACCEPTED"],
          shipping_states: ["CREATED"],
          key: 2,
        },
        {
          title: "shipping",
          value: ["ON_PROCESS"],
          state: ["ACCEPTED"],
          shipping_states: ["SHIPPED", "DELIVERED", "BUYER_ACCEPTED"],
          key: 3,
        },
        {
          title: "completed",
          value: ["COMPLETED"],
          state: ["COMPLETED"],
          shipping_states: ["BUYER_ACCEPTED"],
          key: 4,
        },
        {
          title: "canceled",
          value: ["CANCELED"],
          state: ["CANCELED", "REJECTED", "CANCELEDPAYMENTEXPIRED"],
          shipping_states: ["CREATED"],
          key: 5,
        },
      ],
      data: [],
      page: 1,
      loading: false,
      activeKey: 0,
      modalVisible: false,
      confirmedStatusById: "",
      total_waiting_payment: 0,
      limit: 20,
      total: 0,
      showModalReorder: false,
      showNotificationPreventAddCart: false,
      feedbackCart: [],
      countdownRedirect: 5,
      initInterval: null
    };
  },
  computed: {
    ...mapState(["purchase", "apps"]),
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    changeType(key) {
      this.activeKey = key;
      this.page = 1;
      this.limit = 20;
      const getValueByKey = this.listTab.find((item) => item.key === key);
      this.fetchPurchase(
        getValueByKey?.value,
        false,
        getValueByKey?.shipping_states
      );
    },
    goToCatalogue() {
      this.$router.push({ path: '/purchase/catalogue' })
    },
    goToCart() {
      this.countdownRedirect = 5
      clearInterval(this.initInterval)
      this.$router.push({ path: '/purchase/cart' })
    },
    openModal(id) {
      this.confirmedStatusById = id;
      this.modalVisible = true;
    },
    onReorder(value, id) {
      this.showModalReorder = value
      this.feedbackCart = []
      this.handleGetCart(value, id)
    },
    async handleAddListProductToCart(value, id) {
      const feedback = []
      let responseData = null
      this.$store.dispatch('purchase/GETDETAIL', {
        order_id: id,
        channel_id: this.isLoyalty ? this.$store.state.app.redeem_channel_id : this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          responseData = data
        })
        .catch(() => {
          this.$notification.error({
            message: "Gagal",
            description: "Terjadi kesalahan saat menambahkan produk ke keranjang",
          })
        })
        .finally(() => {
          if (responseData?.items?.length > 0) {
            responseData.items.forEach((item, index) => {
              let payload = {
                cart_id: this.$store.state.purchase.cartId,
                buyer_id: this.$store.state.user.user_data.buyer_id,
                area_id: this.$store.state.user.user_data.area_id,
                item_id: item.catalog_id,
                product_id: item.product_id,
                catalog_sku: item.sku,
                catalog_title: item.title,
                showimg_url: item.image_url,
                qty: item.qty,
                uom: item.uom,
                item_price: item.selling_price,
                currency: responseData.currency || "IDR",
                warehouse_id: responseData.warehouse_id,
              }
              if (this.$store.getters['user/isGuest']) {
                payload = {
                  ...payload,
                  buyer_id: null,
                }
              }
              this.$store.dispatch(this.$store.getters['user/isGuest'] ? 'purchase/ADDTOCARTGUEST' : 'purchase/ADDTOCART', {
                ...payload,
                channel_id: this.isLoyalty ? this.$store.state.app.redeem_channel_id : this.$store.state.app.channel_id,
              })
              .then(() => {
                feedback.push({
                  ...payload,
                  ...item,
                  status: true,
                })
              })
              .catch(() => {
                feedback.push({
                  ...payload,
                  ...item,
                  status: false,
                })
                // const { data } = err.response
                // this.$notification.error({
                //   message: this.$t('purchase.cart.failed'),
                //   description: data && data.message ? data.message : 'Internal Server Error',
                // })
              })
              .finally(() => {
                if (index === responseData.items.length - 1) {
                  this.feedbackCart = feedback
                  if (feedback.filter((item) => item.status).length === feedback.length) {
                    this.initInterval = setInterval(() => {
                      if (this.countdownRedirect > 0) {
                        this.countdownRedirect -= 1
                      } else {
                        this.goToCart()
                      }
                    }, 1000)
                  }
                }
              })
            })
          } else {
            this.$notification.error({
              message: "Gagal",
              description: "Gagal mendapatkan detail produk",
            })
          }
        })
    },
    goToHome() {
      this.$router.push("/dashboard");
    },
    showDetail(param, mode) {
      if (mode == "mobile") {
        if (window.innerWidth > 900) return;
      }
      this.$router.push({path: `/purchase/order/${param}`});
    },
    showHowToBayDetail(param) {
      this.$router.push({path: `/purchase/how-to-pay/${param}`});
    },
    gotToWaitingPayment() {
      this.$router.push({path: "/purchase/waiting-payment"});
    },
    getWaitingPayment() {
      this.loading = true;
      this.$store
        .dispatch("purchase/GETWAITINGPAYMENT", {
          channel_id: this.$store.state.user.user_data.channel_id,
          buyer_id: this.$store.state.user.user_data.buyer_id,
          page: 1,
        })
        .then(({data}) => {
          this.total_waiting_payment = data.total_data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchPurchase(status, moreFetch, shipping_states = []) {
      this.loading = true;
      this.$store
        .dispatch("purchase/GETALLPURCHASEORDER", {
          page: this.page,
          limit: this.limit,
          channel_id: this.$store.state.user.user_data.channel_id,
          status,
          shipping_states: shipping_states,
        })
        .then(({data, total_record, limit}) => {
          const newData = (data || [])?.map(({created_at, ...rest}) => ({
            ...rest,
            created_at: moment(created_at).format("DD MMM YYYY"),
            firstItem: rest.items[0],
            theRestItem: (rest.items && rest.items.length - 1) || "",
            newTotal: Intl.NumberFormat("en-US").format(
              rest.total_value /*- (rest?.payment_data?.credit_limit_usage || 0) */
            ),
            title: this.listTab.find(
              (item) =>
                item.state.includes(rest.status) &&
                item.shipping_states.includes(rest.shipping_state)
            )?.title,
          }));
          const totalRestData = total_record - (data || []).length;
          this.data = moreFetch ? [...this.data, ...newData] : newData;
          this.loading = false;
          this.page = this.page + 1;
          this.total = total_record;
          this.limit = totalRestData < 20 ? totalRestData : limit;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    isBottom() {
      const el = document.querySelectorAll("#wrap");
      const navbarHeight = 151;
      const offsetElement = 5;
      const lastEl = el[el.length - offsetElement];
      const bounds = lastEl.getBoundingClientRect().y;
      const alreadyGetAllData = this.data.length >= this.total;
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        const getValueByKey = this.listTab.find(
          ({key}) => key === this.activeKey
        );
        this.fetchPurchase(
          getValueByKey?.value,
          true,
          getValueByKey?.shipping_states
        );
      }
    },
    onChangeStateOrder(stateOrder) {
      this.$store
        .dispatch("purchase/ACCEPTEDORDERBYUSER", {
          order_id: this.confirmedStatusById,
        })
        .then((data) => {
          this.modalVisible = false;
          this.confirmedStatusById = "";
          this.page = 1;
          const getValueByKey = this.listTab.find(({key}) => key === 0);
          this.fetchPurchase(
            getValueByKey?.value,
            false,
            getValueByKey?.shipping_states
          );
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleGetCart(value, id) {
      let responseData = null
      this.$store.dispatch('purchase/GETCART', {
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          responseData = data
        })
        .catch((err) => {
          console.error(err)
          responseData = null
        })
        .finally(() => {
          if (responseData?.cart_list?.length > 0) {
            responseData.cart_list.forEach((item) => {
              this.$store.dispatch('purchase/DELETECART', {
                channel_id: this.$store.state.user.user_data.channel_id,
                cart_item_id: item.id,
                cart_id: responseData.id,
              })
            })
          }
          this.handleAddListProductToCart(value, id)
        })
    },
  },
  mounted() {
    const getValueByKey = this.listTab.find(({key}) => key === 0);
    this.fetchPurchase(
      getValueByKey?.value,
      false,
      getValueByKey?.shipping_states
    );
    window.addEventListener("scroll", this.isBottom);
    this.getWaitingPayment();
  },
  destroyed() {
    window.removeEventListener("scroll", this.isBottom);
  },
};
</script>

<style lang="scss" scoped>
.btn-order-state {
  border-radius: 8px;
  color: #000;
  border: 1px solid transparent;
  // color: var(--biz-brand-base);
}

.active {
  background: var(--biz-brand-button);
  color: var(--biz-brand-base);
  border-color: var(--biz-brand-button);
}

.inactive {
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 400px;
}

.desc-empty-filter {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 400px;
}
.button-empty-state {
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  padding: 5px 15px;
  color: $black;
  border: 2.25px solid $black;
  border-radius: 6.75px;
}
.tag-status {
  border-radius: 6px;
  padding: 5px 12px;
  font-size: 14px;
}
.hidden-button {
  display: flex !important;
}
.number-product {
  display: flex;
  align-items: center;
}
.waiting-payment-mobile {
  display: none;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.btn-detil-transaksi {
  color: $white;
  background-color: var(--biz-brand-button);
  width: 200px;
}
.waiting-payment-desktop {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 2rem;
  cursor: pointer;
  display: flex;
}
@media only screen and (max-width: 900px) {
  .hidden-button {
    display: none !important;
  }
  .box-price {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  #list-purchase {
    overflow-x: hidden;
    box-sizing: border-box;
  }
  .number-product {
    display: block;
  }
  .waiting-payment-mobile {
    display: flex;
  }
  .waiting-payment-desktop {
    display: none;
  }
}
.rotate-img {
  animation: rotation 1s infinite linear;
  height: 55px;
  width: auto
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
