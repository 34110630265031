<template>
  <Purchase />
</template>

<script>
import Purchase from '@/components/Purchase/index'

export default {
  name: 'purchase.index',
  components: {
    Purchase,
  },
}
</script>
