<template>
  <b-modal hide-footer hide-header centered v-model="showModal" @hidden="resetModal">
    <div class="d-flex flex-column align-items-center text-center px-3">
      <div class="mb-3">
        <Warning :color="'var(--biz-secondary-100)'"/>
      </div>
      <div class="px-5 mb-2" v-html="$t('purchase.catalogue.disclaimer_customer_not_active')"></div>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <button
        type="button"
        class="button-add px-4 rounded-lg"
        @click.prevent="resetModal"
      >
        {{ $t('menu.profile.retur.deposit.close') }}
      </button>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import Warning from '@/components/Icons/Warning.vue'

export default {
  name: 'modal-prevent-add-cart',
  props: {
    showModal: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Warning,
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    resetModal() {
      this.$emit('emitCloseModal', false)
    }
  },
}
</script>

<style lang="scss">
.button-add {
  height: 40px;
  font-style: normal;
  font-weight: 500;
  color: #FFFFFF;
  background: var(--biz-brand-button);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;

  &[disabled] {
    opacity: .5;
  }
}
</style>
